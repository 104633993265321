import Game from 'Components/containers/Game';
import './Mario.scss';

function Mario() {
	return (
		<Game
			title='Mario'
			// url='https://tamgames.net/Games/Mario/mario.html'
			url='https://cadenmarinozzi.github.io/TamGames-GameData/Mario/mario.html'
		/>
	);
}

export default Mario;
