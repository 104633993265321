import Game from 'Components/containers/Game';

function LearnToFly2() {
	return (
		<Game
			title='Learn To Fly 2'
			// url='https://tamgames.net/Games/LearnToFly2/learnToFly2.html'
			url='https://cadenmarinozzi.github.io/TamGames-GameData/LearnToFly2/learnToFly2.html'
		/>
	);
}

export default LearnToFly2;
