import Game from 'Components/containers/Game';
import './FNAF.scss';

function FNAF() {
	return (
		<Game
			title='FNAF'
			// url='https://tamgames.net/Games/1v1LOL/1v1LOL.html'
			url='https://cadenmarinozzi.github.io/TamGames-GameData/FNAF/index.html'
		/>
	);
}

export default FNAF;
