import Game from 'Components/containers/Game';

function BasketballStars() {
	return (
		<Game
			title='BasketballStars'
			url='https://cadenmarinozzi.github.io/TamGames-GameData/BasketballStars/index.html'
		/>
	);
}

export default BasketballStars;
