import Game from 'Components/containers/Game';
import './Slope.scss';

function Slope() {
	return (
		<Game
			title='Slope'
			url='https://cadenmarinozzi.github.io/TamGames-GameData/Slope/slope.html'
		/>
	);
}

export default Slope;
