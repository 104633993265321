import Game from 'Components/containers/Game';
import './UltimateWheelie.scss';

function UltimateWheelie() {
	return (
		<Game
			title='UltimateWheelie'
			url='https://cadenmarinozzi.github.io/TamGames-GameData/UltimateWheelie/ultimateWheelie.html'
		/>
	);
}

export default UltimateWheelie;
