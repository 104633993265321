import Game from 'Components/containers/Game';

function SnakeIO() {
	return (
		<Game
			title='SnakeIO'
			url='https://cadenmarinozzi.github.io/TamGames-GameData/SnakeIO/snakeIO.html'
		/>
	);
}

export default SnakeIO;
