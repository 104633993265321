import Game from 'Components/containers/Game';
import './1v1LOL.scss';

function IvILOL() {
	return (
		<Game
			title='1v1LOL'
			// url='https://tamgames.net/Games/1v1LOL/1v1LOL.html'
			url='https://cadenmarinozzi.github.io/TamGames-GameData/1v1LOL/1v1LOL.html'
		/>
	);
}

export default IvILOL;
