import Game from 'Components/containers/Game';

function UniversalPaperclips() {
	return (
		<Game
			title='Universal Paperclips'
			// url='https://tamgames.net/Games/LearnToFly2/learnToFly2.html'
			url='https://cadenmarinozzi.github.io/TamGames-GameData/UniversalPaperclips/index.html'
		/>
	);
}

export default UniversalPaperclips;
