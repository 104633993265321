import Game from 'Components/containers/Game';

function LearnToFly() {
	return (
		<Game
			title='Learn To Fly'
			// url='https://tamgames.net/Games/LearnToFly/learnToFly.html'
			url='https://cadenmarinozzi.github.io/TamGames-GameData/LearnToFly/learnToFly.html'
		/>
	);
}

export default LearnToFly;
