import Game from 'Components/containers/Game';
import './JetpackJoyriders.scss';

function JetpackJoyriders() {
	return (
		<Game
			title='Jetpack Joyriders'
			// url='https://tamgames.net/Games/JetpackJoyriders/jetpackJoyriders.html'
			url='https://cadenmarinozzi.github.io/TamGames-GameData/JetpackJoyriders/jetpackJoyriders.html'
		/>
	);
}

export default JetpackJoyriders;
