import Game from 'Components/containers/Game';
import './CookieClicker.scss';

function CookieClicker() {
	return (
		<Game
			title='CookieClicker'
			url='https://cadenmarinozzi.github.io/TamGames-GameData/CookieClicker/index.html'
		/>
	);
}

export default CookieClicker;
