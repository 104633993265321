import Game from 'Components/containers/Game';
import './MotoX3M.scss';

function MotoX3M() {
	return (
		<Game
			title='MotoX3M'
			url='https://cadenmarinozzi.github.io/TamGames-GameData/MotoX3M/index.html'
		/>
	);
}

export default MotoX3M;
