import Game from 'Components/containers/Game';
import './Tetris.scss';

function Tetris() {
	return (
		<Game
			title='Tetris'
			// url='https://tamgames.net/Games/1v1LOL/1v1LOL.html'
			url='https://cadenmarinozzi.github.io/TamGames-GameData/Tetris/index.html'
		/>
	);
}

export default Tetris;
