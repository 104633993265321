import Game from 'Components/containers/Game';

function HappyWheels() {
	return (
		<Game
			title='Happy Wheels'
			// url='https://tamgames.net/Games/HappyWheels/happyWheels.html'
			url='https://cadenmarinozzi.github.io/TamGames-GameData/HappyWheels/happyWheels.html'
		/>
	);
}

export default HappyWheels;
