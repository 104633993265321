import Game from 'Components/containers/Game';
import './SubwaySurfers.scss';

function SubwaySurfers() {
	return (
		<Game
			title='SubwaySurfers'
			// url='https://tamgames.net/Games/SubwaySurfers/subwaySurfers.html'
			url='https://cadenmarinozzi.github.io/TamGames-GameData/SubwaySurfers/subwaySurfers.html'
		/>
	);
}

export default SubwaySurfers;
